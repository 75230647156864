import React, {useState} from 'react';
import Slider from 'react-slick';
import SEO from './SEO.js';
import StructuredData from '../StructuredData';
import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/Beulah Heights Logo.png';
import Energy from '../images/energy.jpg';
import Card1 from '../images/card1.jpg';
import Card3 from '../images/card3.jpg';
import calendar from '../images/calender-date-day-time-clock-schedule-event-comments-schedule-ico-115629448074tns0ghtw6-removebg-preview.png';
import mapper from '../images/535239.png';
import Graduation from '../images/graduations.jpg';
import Morio from '../images/Morio.jpg';
import Flying from '../images/flying.jpg';
import ManyStudents from '../images/Many Students.jpg';
import BlackGirl from '../images/black girl.jpg';
import UniStudents from '../images/university students.jpg';
import CompClasses from '../images/comp-classes.jpg';
import ComputerClass from '../images/computer class.jpg';
import InClass from '../images/in class.jpg';
import ComputerClassroom from '../images/computer classroom.jpg';
import emailjs from 'emailjs-com'; // Import EmailJS


function Home() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [email, setEmail] = useState(''); // Add this line

const handleMouseEnter = () => {
  setShowDropdown(true);
};

const handleMouseLeave = () => {
  setShowDropdown(false);
};
const handleSubmit = (e) => {
  e.preventDefault();

  const templateParams = {
    to_email: 'subscriptions@beulah.co.ke', // Your email address
    from_email: email,
  };

  emailjs.send('service_1hxnt3b', 'template_umgbz1j',templateParams, 'PE7p7aFj5ZDrDMrwE')
    .then((response) => {
      console.log('Email sent successfully:', response);
      alert('Subscription successful!');
      setEmail('');
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      alert('Subscription failed. Please try again.');
    });
};
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4200,
  arrows: false
};

return (
  <>
  <SEO title="Beulah Heights University | Africa" description="Home Page For Our Beulah Heights University - Africa Website"/>
  <StructuredData />
  <div className="begin">
    <main className="main">
    
    <Slider {...settings}>
        <div className="hero1"  >
          <div className="hero-content">
            <div className="heros">
            <h1><strong>Beulah Heights University - Africa.</strong></h1>
            <p>A world class university of your choice.</p>
            <a href="https://beulah.edu/" className="btn1"><strong>Read More</strong></a>
            </div>
          </div>
        </div>
        <div className="hero2" >
          <div className="hero-content">
          <div className="heros">
            <h1><strong>Welcome to Our Campus</strong></h1>
            <p>Explore our diverse programs.</p>
            <a href="https://beulah.edu/academics/" className="btn1"><strong>Learn More</strong></a>
            </div>
          </div>
        </div>
        <div className="hero3" >
          <div className="hero-content">
          <div className="heros">
            <h1><strong>Our programs are</strong></h1>
            <p>Accredited, affordable and accessible.</p>
            <a href="https://beulah.edu/academics/" className="btn1"><strong>Learn More</strong></a>
            </div>
          </div>
        </div>
        <div className="hero4" >
          <div className="hero-content">
          <div className="heros">
            <h1><strong>Inquire about</strong></h1>
            <p>Beulah Heights University Programs.</p>
            <a href="https://beulah.edu/academics/" className="btn1"><strong>Learn More</strong></a>
            </div>
          </div>
        </div>
        <div className="hero5" >
          <div className="hero-content">
            <div className="heros">
            <h1><strong>Education</strong></h1>
              <p>"Education is the most powerful weapon which you can use to change the world."</p>
              <p><strong>Nelson Mandela.</strong></p>
            </div>
          </div>
        </div>
        {/* Add more slides as needed */}
      </Slider>

      <br/>
      <h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Explore Our Programs</h1>

      <div class="middle-section">
  <div class="study">
      <img class="image" src={Graduation} alt="Graduation"/>
      <div class="content">
      <a href="https://beulah.edu/academics/" className="link-style">
          <h3>Study</h3>
          </a>
          <ul>
              
              <li><a href="https://beulah.edu/academics/undergraduate/"className="link-style">Undergraduate</a></li>
              <li><a href="https://beulah.edu/academics/master-programs/"className="link-style"> Master Programs</a></li>
              <li><a href="https://beulah.edu/academics/doctoral-programs/"className="link-style"> Doctoral Programs</a></li>
              <li><a href="/Pdc"className="link-style"> Professional Development Courses</a></li>
              <li><a href="https://beulah.edu/academics/library/" className="link-style">Research</a></li>
         
          
          </ul>
      </div>
  </div>
  
</div>
<br/>
<br/>

<div style={{backgroundColor:'beige'}}>
<h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Undergraduate Programs</h1>
    <br/>
  <div className="container">
      <div className="row">
        <div className="col-md-4">
          <a href="https://beulah.edu/academics/undergraduate/aa-ba-in-business/">
            <div className="imagez">
              <img src={BlackGirl} alt='A student'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire" className='homelink'><strong>AA/BA in Business</strong></Link> 
            </h3>
            <p>
            An Associates of Business Administration or a Bachelor of Business Administration at Beulah Heights University will 
            give you the confidence to enter the workplace with knowledge of business theories and concepts, quantitative reasoning, 
            strategic communication skills, and technological skills appropriate for varied business environments. Beulah Heights 
            University offers one-on-one academic advising, that is tailored to your needs and guarantees your success throughout 
            the program. You will be immersed in a learning environment dedicated to wholistic development of all students. 
            Students who pursue a BBA may qualify for positions in Marketing, Human Resources, Finance, or Management. 
            Consider adding a minor in Accounting to increase your chances of success in the job market.
            </p>
        
            <div class="back-button-container">
            <a href="/inquire" class="back-button">
              <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
            </a>
          </div> 
        </div>

        <br/>

        <div className="col-md-4">
          <a href="https://beulah.edu/academics/undergraduate/religious-studies/">
            <div className="imagez">
              <img src={UniStudents} alt='University Students'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>AA/BA in Religious Studies</strong></Link>
            </h3>
            <p>
            The Associates of Arts or Bachelor of Arts in Religious Studies prepares students for ministry within the context 
            of culture and the church. Our classes in Theology, Applied Theology, and Bible Studies allow students to engage 
            in direct study of the Bible. The knowledge you gain in this program will help you develop a ministry that will 
            impact and shape the people you serve. At the end of the program, you will develop an intensive knowledge of 
            scriptural content. You will understand hermeneutical principles and be able to defend your faith against scientism, 
            historical criticism, philosophy, cults, and non-Christian religions.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button" style={{marginTop:'120px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>

        <div className="col-md-4">
          <a href="https://beulah.edu/academics/undergraduate/aa-ba-in-leadership-studies/">
            <div className="imagez">
              <img src={CompClasses} alt='Computer Class'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>AA/BA in Leadership Studies</strong></Link>
            </h3>
            <p>
            The Associate of Arts or Bachelor of Arts in Leadership at Beulah Heights University will help you develop 
            skills in communication, conflict management, ethics, and leadership theories and models. The program offers 
            a broad focus that prepares students for leadership positions in a variety of organizations. The curriculum 
            prepares you to become a change agent. At the end of this program, you will be eligible to pursue careers in 
            Human Resources, Project Management, Strategic Planning, Leadership Coaching and Training, Sales, or Public 
            Relations. The study of leadership is multidisciplinary and the skills developed are transferable.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button" style={{marginTop:'95px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>
      </div>
  </div>  
  

  <br/>
  <br/>
  </div>
  <h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Master Programs</h1>
    <br/>
  <div className="container">
      <div className="row justify-content-evenly">
        <div className="col-md-5">
          <a href="https://beulah.edu/academics/master-programs/ma-in-leadership/">
            <div className="image">
              <img src={ComputerClass} alt='A computer Class'/>
            </div>
          </a>
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Master of Arts in Leadership</strong></Link>
            </h3>
            <p>
            A Master of Arts in Leadership empowers students to lead in any type of organization. Individuals who will find this 
            program most useful are aspiring Senior Executives, Corporate Trainers, Human Resource Managers, School Leaders or 
            other similar roles. Your passion for leadership can be rewarding regardless of your field. Within our program we 
            will teach you how to become a relevant and impactful leader.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button" style={{marginTop:'20px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>

        <br/>

        <div className="col-md-5">
          <a href="https://beulah.edu/academics/master-programs/ma-in-religious-studies/">
            <div className="image">
              <img src={InClass} alt='Students In Class'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Master of Arts in Religious Studies</strong></Link>
            </h3>
            <p>
            Religious Studies is a multidisciplinary concentration that allows students to pursue studies in the Bible, Theology, 
            Language, Communications, and Research. The Master of Religious Studies program is a 42 credit hour program and students 
            that requires students to complete a capstone project at the end. The program prepares students for careers in Ministry, 
            Teaching, Non-profit Management, Mediation, Community Development etc.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button">
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
        </div>

      </div>
  </div>
  <br/>
  <br/>
  <div style={{backgroundColor:'beige'}}>
  
    <br/>
  <div className="container">
      <div className="row justify-content-evenly">
        <div className="col-md-5">
          <a href="https://beulah.edu/academics/master-programs/mba/">
            <div className="image">
              <img src={ComputerClassroom} alt='A Computer Classroom'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Master of Business Administration</strong></Link>
            </h3>
            <p>
            Beulah Heights University is committed to assisting individuals who are ready to elevate and enhance their managerial 
            proficiency through the integration of academic and practical training. Our MBA is fast and affordable and will prepare 
            you to enhance your skills and experiences in an environment that reflects sound Christian values and morals. An MBA from 
            Beulah Heights University will prepare you to be successful in the current global environment.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button"style={{marginTop:'46px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
            <br/>
        </div>

        <div className="col-md-5">
          <a href="https://beulah.edu/academics/master-programs/master-of-divinity/">
            <div className="image">
              <img src={Flying} alt='flying gowns'/>
            </div>
          </a>
            
            <br/>
            <h3>
              <Link to="/inquire"className='homelink'><strong>Master of Divinity</strong></Link>
            </h3>
            <p>
            The Master of Divinity is a professional degree that offers concentrations in Chaplaincy and Leadership. While it takes 
            longer than a Master of Arts in Religious Studies it is an adaptable degree. It is also a requirement to pursue a Doctor 
            of Ministry degree. A Master of Divinity is required for ministry and becoming a Pastor but you can also become a Chaplain, 
            a Missionary, Youth Worker, Advocate or Communication Organizer.
            </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button"style={{marginTop:'46px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
            </div>
            <br/>
        </div>
      </div>
  </div>
  </div>
  
  <br/>
  <div>
 
  <h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Doctoral Programs</h1>
    <br/>

  <div className="container">
    <div className="row justify-content-evenly">
      <div className="col-md-5">
        <a href="https://beulah.edu/academics/doctoral-programs/doctor-of-ministry/">
          <div className="image">
            <img src={ManyStudents} alt='Many Students'/>
          </div>
        </a>
           
          <br/>

        <h3>
          <Link to="/inquire"className='homelink'><strong>Doctor Of Ministry</strong></Link>
        </h3>
              <p>Beulah Heights University's DMin curriculum focuses on equipping leaders to meet the chalenges of leading in a Post-Christendom
                context. The curriculum design encourages ongoing spiritual formation, an ability to think critically about issues confronting our world,
                a devoted Christian lifestyle, and an active engagement of scholarship and ministry practice. Through a hybrid based and online
                instructional learning model, program competencies are taught through classroom instruction and synchronous and asynchronous
                learning using digital education platforms. Students complete a context and action research-based dissertation/ministry project,
                which will reflect the strategic work and learning the student encountered during the program. Persons who earn the BHU DMin
                degree (depending on the degree track) are credentialed and trained to serve in several ministry professional areas; Church Ministry,
                Chaplaincy, Para-church ministries, Higher-education/Seminary Faculty, Advocacy, Entrepreneurship, Conflict Resolution Specialist, etc.
              </p>
                
          <div class="back-button-container">
            <a href="/inquire" class="back-button">
              <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
            </a>
          </div>
         
      </div>
      
      <br/>

      <div className="col-md-5">
        <a href="https://beulah.edu/academics/doctoral-programs/phd-doctor-of-philosophy/">
          <div className="image">
            <img src={Morio} alt='An African Student'/>
          </div>
        </a>
          
          <br/>

          <h3>
            <Link to="/inquire"className='homelink'><strong>Doctor of Philosophy</strong></Link>
          </h3>

          <p>The Doctor of Philosophy (Ph.D.) degree in Organizational Leadership is a 60-hour program that includes foundation, research, discipline
            and elective area coursework. While culminating with a comprehensive examination and dissertation. Coursework is focused on the
            blending of theory, real-world and practical applications that develop the knowldge, skills, and dispositions needed to strategically
            resolve conflict, manage high performing teams, lead multicultural and multigenerational workspaces; While intergrating the ideas
            and standard operations of globalization. Our curriculum is designed to further develop aspiring leaders who have the desire to further
            cultivate their knowledge base while enhancing the competencies needed to lead strategically and efficiently. In addition to leadership
            studies, discipline courses are tailored to reflect Business, Education, Ministry and the Social Science. All of which present principles for
            the growth and development for both a well rounded course of study and student.
          </p>

            <div class="back-button-container">
              <a href="/inquire" class="back-button"style={{marginTop:'20px'}}>
                <span class="arrow">&#8592;</span> INQUIRE ABOUT PROGRAMME
              </a>
          </div>

      </div>
        
    </div>

  </div>  
  <br/>
</div>


{/* Professional Development Courses section */}
<div style={{backgroundColor:'beige'}}>
<div className="App">
<header className="App-header">
<h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Professional Development Courses</h1>

  <p>
    The professional development programs provides opportunities for continuing education to participants 
    to expand their career and professional bandwidth through enhanced skills training and professional development.
     
    The training equips the learners with skills, knowledge and insights in their industry. This is keeping with the 
    vision and mission of Beulah Heights University to develop relevant Christian leaders for the ministry and marketplace. 
  </p><br/>

  <h1 className='App-title'>Why Enroll for our Short Term Program?</h1>
    <p> 
        <strong>•</strong> They are Affordable &nbsp;
        <strong>•</strong> They are Customized &nbsp;
        <strong>•</strong> They are Relevant &nbsp;
        <strong>•</strong> They have Expert Facilitators <br/>
        <a href="/Pdc" class="back-button"style={{marginTop:'20px'}}>
          <span class="arrow">&#8592;</span> EXPLORE COURSES
        </a>
    </p>
    <br/>

  {/* Events section */}
    <h1 style={{textAlign: 'center', color: '#004080', marginTop: '10px', fontFamily:'Poppins, sans-serif',fontWeight:'bold'}}>Events</h1>
  <section className='cards'>
    <div className="card">
      <div className="card-header">
        <div className="date">
          04
          <span className="month">NOV</span>
        </div>
        <div className="card-title">
          Bussiness Intelligence
        </div>
      </div>
      <img src={Card1} alt="Bussiness Intelligence" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="learn-how-to">
        <div className="list">
          Learn How To:
        
          Create Databases
        
          Analyze dashboards
        </div>
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
             <strong>2024-04-11 @ 08:30 AM - 2024-06-11 @ 04:30 PM</strong>
          </div>
          <div className="location">
            <img src={mapper} alt="Time Image" className="time-image" /><strong>BHU Africa</strong>
          </div> 
          <a href="#" class="back-button"> <span class="arrow">&#8592;</span>Class & Training</a>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">
        <div className="date">
          12
          <span className="month">NOV</span>
        </div>
        <div className="card-title">
          Energy Management
        </div>
      </div>
      <img src={Energy} alt="Energy Management" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="location">
          <strong>
          Venue: BHU Africa
        
          Date: 12 Nov. 2024
      
          Cost: Ksh. 15,000
          </strong>
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
            <strong>2024-12-11 @ 10:30 AM - 2024-14-11 @ 03:30 PM</strong>
          </div>
          <div className="location">
          <img src={mapper} alt="Time Image" className="time-image" /><strong>BHU Africa</strong>
          </div>
          <a href="#" class="back-button"> <span class="arrow">&#8592;</span>Class & Workshop</a>
        </div>
      </div>
    </div>
    <div className="card">
      <div className="card-header">
        <div className="date">
          18
          <span className="month">NOV</span>
        </div>
        <div className="card-title">
          Online Writing
        </div>
      </div>
      <img src={Card3} alt="Online Writing" className="card-image"/>
      <div className="card-content">
      <div className="top-content">
        <div className="location">
          <strong>
          Venue: BHU Africa
        
          Date: 18 Nov. 2024
      
          Cost: Ksh. 20,000
          </strong>
        </div>
        </div>
        <div className="card-footer2">
          <div className="date-time2">
          <img src={calendar} alt="Location Image" className="calendar-icon" />
            <strong>2024-18-12 @ 08:30 PM - 2024-22-12 @ 01:30 PM</strong>
          </div>
          <div className="location">
          
         <img src={mapper} alt="Time Image" className="time-image" /> <strong>BHU Africa</strong></div>
          
         <a href="#" class="back-button"> <span class="arrow">&#8592;</span>Training & Workshop</a>
        </div>
      </div>
    </div>
    
  </section>
</header>
</div>
</div>

    </main>

    <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(+254) 743 777 277</a></li>
                <li><a href="mailto:info@beulah.ac.ke">info@beulah.ac.ke</a></li>
                <li><a href="www.beulah.ac.ke">www.beulah.ac.ke</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights Global Initiative</strong></h4>
              <h4>Daystar Valley Road Campus</h4>
              <ul>
                <li>P.O. BOX 40858 - 00100</li>
                <li>NAIROBI, KENYA</li>
                <li>(+254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/library/">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="https://beulah.edu/academics/master-programs/">Graduate</a></li>
                <li><a href="https://beulah.edu/academics/doctoral-programs/">Post Graduate</a></li>
                <li><a href="/Pdc">Professional Development</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/OurStory">Our Story</a></li>
                <li><a href="/Partnerships">Partner With Us</a></li>
                <li><a href="/About">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
               <input type="text" placeholder="Email Address"style={{width: '200px'}} />
            </div>
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
    </div>
    </>
  );
}

export default Home;