import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Tuition = () => {
  return (
    <div className="begin">
        <h1 className="text-center" style={{ color: 'white', backgroundColor:'#004080', maxWidth:'100%', margin: '0' }}>Tuition Fees</h1>
        <br/>

      <div className="row justify-content-evenly">
        <div className="col-md-7">
          <div className="partnertxt">

            <p><strong>Africa Outreach Scholarship</strong></p>

            <p>
                Students enrolled at Beulah Heights University through the Global Learning initiative benefit from the
                Africa Outreach Scholarship of up to 75% of the Tuition fees.<br/>

                <a href="/inquire" class="back-button"style={{marginTop:'20px'}}>
                    <span class="arrow">&#8592;</span> INQUIRE NOW
                </a>
            </p>

          </div>
        </div>

      </div>
   
    </div>
  );
}

export default Tuition;