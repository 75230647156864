import React from 'react';

import profileImage from '../images/Dr Nzioki.jpg'; // Ensure you update this path

const ProfilePage = () => {
  return (
    <div className="profile-page">
        
      <header className="profile-header">
        <h1 style={{fontWeight:'bold', fontSize:'60px' }}>DR. Joseph Nzioki</h1>
      </header>
    
      <div className="profile-content">
        <img className="profile-image" src={profileImage} alt="Benson Karanja" />
        <div className="profile-text">
          <h2>DR. Joseph Nzioki, Executive Director</h2>
          <p>
          Joseph Nzioki is an award winning global educator, entrepreneur, Philanthropist, thought leader, leadership strategist, lecturer, researcher, speaker, minister and trusted advisor to religious, corporate, political and academic leaders. He has over 15 years’ experience in higher education, leadership development, consulting and Nonprofit sector. He has proven track record and experience in leading new ventures, building partnerships and collaborations, capacity building, fundraising, corporate governance, E-learning, policy development, curriculum development and global higher education regulation and compliance. He has lectured and mentored students in leading academic institutions and consulted for government, Para-church organizations and Corporations. 
He has distinguished himself as a sedulous and versatile leader, whose life mission is guided by the principles of servant leadership. He is engaged in community service through youth mentorship and development programs and as a board member for various educational institutions. He was awarded the outstanding Leadership Award in 2021 for his work in promoting access to quality higher education through online mode of delivery in Africa.
He graduated student of the year with a dual Bachelor of Arts degree (Magna Cumlaude) in International Economic Development/Biblical Studies and two Master of Arts degrees in Leadership Studies, Philosophy and Divinity from Beulah Heights University. He subsequently attended Regent University USA and Unicaf University Cyprus/ Zambia for his doctoral studies.
</p>

        
        </div>
      
      </div>
      <div class="back-button-container">
      <a href="/About" class="back-button">
        <span class="arrow">&#8592;</span> BACK TO FACULTY & STAFF DIRECTORY
      </a>
    </div>
    </div>
  );
};

export default ProfilePage;