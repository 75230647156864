import React, { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/Beulah Heights Logo.png';
import Logo from '../images/Bhu-logo-wiki.png';
import image1 from '../images/staff_benson-karanja21-22.jpg';
import image2 from '../images/Dr Nzioki.jpg';
import image3 from '../images/Dr.-Jacskon-1-square.jpg';
import image4 from '../images/faculty_wilson-wes.jpg';
import image5 from '../images/Peter-Karanja.jpg';
import image6 from '../images/Julian+Kyula-removebg-preview.png';
import {Link} from 'react-router-dom'

function About() {
  
  return (
    <div className="begin">
      <div className="containers" style={{width:'100%'}}>
      <h1 className="text-center" style={{ color: 'white', backgroundColor:'#004080', maxWidth:'100%', margin: '0' }}>The BHU Africa Team</h1>
      <br/>
     
    <br/>
        <div className="row mt-4">
          <div className="col-md-3">
            <a href="/usa-team" style={{ textDecoration:'none'}}>
              <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none' }}>
                <div className="card-body">
                  <img
                    src={Logo}
                    alt="BHU Logo"
                    className="img-fluid"
                    style={{ width: '90%', height: '80%' }}
                  />
                 
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-3">
           
              <div className="card"  style={{ width: '60%', height: '70%', borderStyle: 'none',  }}>
              <a href="/ProfilePage" style={{ textDecoration:'none', }}>
                <img
                  src={image1}
                  alt="Benson M. Karanja"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                 </a>
                <div className="card-body" style={{ marginLeft:'-15px'}}>
                  <h5 className="card-title" style={{ fontSize: '15px', }}>
                    Benson M. Karanja,
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                    Ed.D. H.S.C., M.Β.Α, Μ.L.S., Β.Α.
                    <br />
                    President
                    <br />
                    Benson.Karanja@Beulah.edu
                  </p>
                </div>
              </div>
           
          </div>
          <div className="col-md-3">
           
            <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none', textAlign: 'left' }}>
            <a href="/ProfilePage5" style={{ textDecoration:'none'}}>
            <img
                  src={image2}
                  alt="DR. Joseph Nzioki"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                </a>
                <div className="card-body"style={{ marginLeft:'-15px'}}>
                  <h5 className="card-title" style={{ fontSize: '15px' }}>
                    Dr. Joseph Nzioki
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                    
                    Executive Director
                   <br/>
                    Joseph.Nzioki@Beulah.edu
                  </p>
                </div>
              </div>
            
          </div>
          <div className="col-md-3">
           
            <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none', textAlign: 'left' }}>
            <a href="/ProfilePage2" style={{ textDecoration:'none'}}>
            <img
                  src={image3}
                  alt="Rodney Jackson"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                 </a>
                <div className="card-body" style={{ marginLeft:'-15px'}} >
                  <h5 className="card-title" style={{ fontSize: '15px' ,}}>
                    Rodney Jackson, Psy. D., M. Div.
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                    Academic Dean /
                    <br />
                    Vice President of Academic Affairs
                    <br />
                    Rodney.Jackson@Beulah.edu
                  </p>
                </div>
              </div>
           
          </div>
        </div>

        <div className="row mt-4" style={{ marginLeft: '330px' }}>
          <div className="col-md-4">
          <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none', textAlign: 'left' }}>
            <a href="/peter-karanja" style={{ textDecoration:'none'}}>
            <img
                  src={image5}
                  alt="Peter Karanja"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                  </a>
                <div className="card-body"style={{ marginLeft:'-15px'}}>
                  <h5 className="card-title" style={{ fontSize: '15px' }}>
                    Peter Karanja,
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                    B.S., M.B.A. Chief Operating Officer
                    <br />
                    Peter.Karanja@Beulah.edu
                  </p>
                </div>
              </div>
            
            
          </div>
          <div className="col-md-4">
         
          <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none', textAlign: 'left' }}>
            <a href="/ProfilePage4"style={{ textDecoration:'none'}}>
            <img
                  src={image6}
                  alt="Julian Kyula"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                </a>

                <div className="card-body" style={{ marginLeft:'-15px'}}>
                  <h5 className="card-title" style={{ fontSize: '15px', }}>
                    Dr. Rev. Julian Kyula,
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                  African Ambassador and promoter <br/>
                  of Beulah Heights University  
                    
                  </p>
                </div>
              </div>
           

          </div>
          <div className="col-md-4">
           
            
              
              <div className="card" style={{ width: '80%', height: '70%', borderStyle: 'none', textAlign: 'left' }}>
            <a href="/ProfilePage3" style={{ textDecoration:'none'}}>
            <img
                  src={image4}
                  alt="Wesley Wilson"
                  className="card-img-top"
                  style={{ width: '200px', height: '200px' }}
                />
                </a>
                <div className="card-body"style={{ marginLeft:'-15px'}}>
                  <h5 className="card-title" style={{ fontSize: '15px' }}>
                    Wesley Wilson Ph.D.
                  </h5>
                  <p className="card-text" style={{ fontSize: '13px' }}>
                    
                  Professor of Leadership <br/>
                  and Bussiness studies.
                   
                  </p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(+254) 743 777 277</a></li>
                <li><a href="mailto:info@beulah.ac.ke">info@beulah.ac.ke</a></li>
                <li><a href="www.beulah.ac.ke">www.beulah.ac.ke</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights Global Initiative</strong></h4>
              <h4>Daystar Valley Road Campus</h4>
              <ul>
                <li>P.O. BOX 40858 - 00100</li>
                <li>NAIROBI, KENYA</li>
                <li>(+254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/library/">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="https://beulah.edu/academics/master-programs/">Graduate</a></li>
                <li><a href="https://beulah.edu/academics/doctoral-programs/">Post Graduate</a></li>
                <li><a href="/Pdc">Professional Development</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/OurStory">Our Story</a></li>
                <li><a href="/Partnerships">Partner With Us</a></li>
                <li><a href="/About">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
               <input type="text" placeholder="Email Address"style={{width: '200px'}} />
            </div>
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
     
    </div>
  );
}

export default About;