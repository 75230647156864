import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "My Website",
          "url": "https://www.beulah.ac.ke"
        }
      `}
    </script>
  </Helmet>
);

export default StructuredData;
