import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import PayPalButton from './PayPalButton';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/Beulah Heights Logo.png';


const Supportbhu = () => {

  const handleSuccess = () => {
    alert('Donation successful! Thank you for your support.');
  };

  return (
    <div className="begin">
      <h1 className="text-center" style={{color: 'white', backgroundColor:'#004080', maxWidth:'100%', margin: '0'}}>Support BHU Africa</h1>
      <br/>
     
        <div className="row ">
          <div className="col-md-8">
            <div className="partnertxt">
                <p><strong>Dear BHU Supporters,</strong> </p>

                <p> 
                    We are grateful for your commitment to Beulah Heights University. Your
                    contributions serve our institution, students, and our mission to offer
                    education grounded in faith and service to the community.
                </p>

                <p><strong>Because of your support, we are able to:</strong></p>
                    
                <p>
                    <strong> Empower Students:</strong> Your donations help us provide scholarships,
                    resources, and opportunities to our students so that they can pursue
                    their dreams and make a meaningful difference in the communities in
                    which they live.
                </p>

                <p>
                  <strong> Enhance Facilities:</strong> With your support, we have improved our
                  campus facilities, thus creating an environment where students can
                  learn and thrive.
                </p>

                <p>
                  <strong> Expand Programs:</strong> Your supprot enables us to expand our program
                  offerings, ensuring that we offer relevant education.
                  Please consider continuing your partnership with Beulah Heights
                  University. Your support is vital for helping us sustain our mission and
                  expand our impact.
                </p>

                <p>
                  <strong> Ways to Give:</strong><br/>
                  <strong> Online Giving:</strong> You can make a secure online donation through our 
                  website at https://beulah.edu/support-bhu/ 
                </p>

                <p>
                  <strong> By Mail:</strong> If you prefer to send your contribution by mail, please make
                  your check payable to Beulah Heights University and send it to:
                  Beulah Heights University 892 Berne Street Atlanta, GA, 30316.
                </p>

                <p>
                  Together, we can make a difference in the lives of students around the
                  world. Thank you for being essential to the Beulah Heights University
                  family and mission. Your generosity inspires us and strengthens our
                  resolve to fulfil our mission.
                </p>

              </div>
          </div>

          <div className="col-md-4">
            <div className="container">
              <div className="donate">
                <h1><strong>DONATE NOW</strong></h1>
                <br/>
                    <PayPalButton />
              </div>
            </div>
          </div>
        </div>  
      
    <br/>  
     
    <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(+254) 743 777 277</a></li>
                <li><a href="mailto:info@beulah.ac.ke">info@beulah.ac.ke</a></li>
                <li><a href="www.beulah.ac.ke">www.beulah.ac.ke</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights Global Initiative</strong></h4>
              <h4>Daystar Valley Road Campus</h4>
              <ul>
                <li>P.O. BOX 40858 - 00100</li>
                <li>NAIROBI, KENYA</li>
                <li>(+254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/library/">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="https://beulah.edu/academics/master-programs/">Graduate</a></li>
                <li><a href="https://beulah.edu/academics/doctoral-programs/">Post Graduate</a></li>
                <li><a href="/Pdc">Professional Development</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/OurStory">Our Story</a></li>
                <li><a href="/Partnerships">Partner With Us</a></li>
                <li><a href="/About">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
               <input type="text" placeholder="Email Address"style={{width: '200px'}} />
            </div>
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
    </div>
  );
}

export default Supportbhu;