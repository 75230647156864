import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/inc/Navbar';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Contactus from './components/pages/Contactus';
import Media from './components/pages/Media';
import Partnerships from './components/pages/Partnerships';
import Supportbhu from './components/pages/Supportbhu';
import InquirePage from './components/pages/InquirePage';
import ProfilePage from './components/pages/ProfilePage';
import ProfilePage2 from './components/pages/ProfilePage2';
import ProfilePage3 from './components/pages/ProfilePage3';
import ProfilePage4 from './components/pages/ProfilePage4';
import Scholarship from './components/pages/Scholarship';
import ProfilePage5 from './components/pages/ProfilePage5';
import OurStory from './components/pages/OurStory';
import Tuition from './components/pages/Tuition';
import Pdc from './components/pages/Pdc';
import EnrollNow from './components/pages/EnrollNow';
import SEO from './components/pages/SEO';
import StructuredData from './components/StructuredData';

function App() {
  const [suggestions, setSuggestions] = useState([]);
  const data = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/About' },
    { label: 'Contact Us', path: '/Contactus' },
    { label: 'Media', path: '/Media' },
    { label: 'Partnerships', path: '/Partnerships' },
    { label: 'Support BHU', path: '/Supportbhu' },
    { label: 'Inquire', path: '/inquire' },
    { label: 'Profile Page', path: '/ProfilePage' },
    { label: 'Profile Page 2', path: '/ProfilePage2' },
    { label: 'Profile Page 3', path: '/ProfilePage3' },
    { label: 'Profile Page 4', path: '/ProfilePage4' },
    { label: 'Profile Page 5', path: '/ProfilePage5' },
    { label: 'Scholarship', path: '/Scholarship'},
    { label: 'OurStory', path: '/OurStory'},
    { label: 'Tuition', path: '/Tuition'},
    { label: 'Pdc', path: '/Pdc'},
    { label: 'EnrollNow', path: '/EnrollNow'},
    { label: 'SEO', path: '/SEO'},
    { label: 'StructuredData', path: '/StructuredData'}
  ];
  const handleSearch = (query) => {
    if (query) {
      const filteredSuggestions = data.filter(item =>
        item.label.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };
  return (
    <Router>
   <Navbar onSearch={handleSearch} suggestions={suggestions} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/inquire" element={<InquirePage />} />
        <Route path="/ProfilePage" element={<ProfilePage />} />
        <Route path="/ProfilePage2" element={<ProfilePage2 />} />
        <Route path="/ProfilePage3" element={<ProfilePage3 />} />
        <Route path="/ProfilePage4" element={<ProfilePage4 />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Media" element={<Media />} />
        <Route path="/Partnerships" element={<Partnerships />} />
        <Route path="/Supportbhu" element={<Supportbhu />} />
        <Route path="/Scholarship" element={<Scholarship />} />
        <Route path="/ProfilePage5" element={<ProfilePage5 />} />
       <Route path='/Ourstory' element={<OurStory/>}/>
       <Route path='/Tuition' element={<Tuition/>}/>
       <Route path='/Pdc' element={<Pdc/>}/>
       <Route path='/EnrollNow' element={<EnrollNow/>}/>
       <Route path='/SEO' element={<SEO/>}/>
       <Route path='/StructuredData' element={<StructuredData/>}/>

</Routes>
    </Router>
  );
}

export default App;
