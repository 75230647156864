import React, { useEffect, useState } from 'react';

const PayPalButton = () => {
  const [amount, setAmount] = useState('');
  const [purpose, setPurpose] = useState('');
  const [comment, setComment] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    // Load the PayPal script
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=AS4JYQKkQCfCHJwrS7yBfZ-oFkOYZ0huIsrzxDwr_9sYuIwj0MiCjIpgAGeekfApTt-ac0RU85NK68jn`; // Replace YOUR_CLIENT_ID with your actual Client ID
    script.async = true;
    script.onload = () => {
      if (window.paypal) {
        window.paypal.Buttons({
          createOrder: (data, actions) => {
            if (!amount || !purpose) {
              alert('Please enter both amount and purpose.');
              return;
            }
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: amount, // Use the user-defined amount
                },
                description: purpose, // Add the purpose as a description
              }]
            });
          },
          onApprove: async (data, actions) => {
            try {
              await actions.order.capture();
              alert('Transaction completed by ' + data.payer.name.given_name);
            } catch (error) {
              console.error('Capture Error:', error);
            }
          },
          onError: (err) => {
            console.error('PayPal Error:', err);
            alert('An error occurred while processing your payment. Please try again later.');
          }
        }).render('#paypal-button-container');
      }
    };
    script.onerror = () => {
      console.error('Failed to load PayPal SDK');
    };
    document.body.appendChild(script);
  }, [amount, purpose]);

  return (
    <div>
      <input
        className="form-control me-2"
        type="text"
        placeholder="Please Write Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br/>
      <input
        className="form-control me-2"
        type="number"
        placeholder="Enter Your Amount in $ (USD)"
        min="0.01"
        step="0.01"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <br/>
      <input
        className="form-control me-2"
        type="text"
        placeholder="Specify The Purpose"
        value={purpose}
        onChange={(e) => setPurpose(e.target.value)}
      />
      <br/>
      <input
        className="form-control me-2"
        type="text"
        placeholder="Your Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <br/>
      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalButton;