import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import FooterLogo from '../images/Beulah Heights Logo.png';
import {Link} from 'react-router-dom'

function About() {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
  };

  return (
    <div className="begin">
      <div className="containers" style={{width:'100%'}}>
      <h1 className="text-center" style={{ color: 'white', backgroundColor:'#004080', maxWidth:'100%', margin: '0' }}>About BHU Africa</h1>
      <br/>
      <div className="container">
        <p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
        <strong>Beulah Heights Global Initiative</strong> is a global learning initiative that was launched by the president of Beulah Heights University. leveraging on technology, the initiative is affording students from across Africa access to quality, affordable and accredited higher education at Beulah Heights University in Atlanta GA USA. The initiative has opened its doors to students from different countries in Africa such as; Kenya, Democratic Republic of Congo, Liberia, Brazil, Kenya and Nigeria. The initiative through the Africa Outreach scholarship extends generous and subsidized tuition scholarship to students domiciled in Africa who study online.   
The initiative has partnered with governments, Universities, churches, and organizations across Africa with the sole purpose of educating and nurturing the next generation of global leaders. Students recruited to Beulah Heights University through the initiative benefit from the Africa outreach scholarship and utilize Brigtspace an interactive, virtual and engaging digital learning platform that allows students who do not have the professional or geographical flexibility of the traditional student to pursue their higher education goals from anywhere in the world. 
The initiative also organizes study abroad programs, where students are exposed to the global context of education. This exposure enables them to develop a global mindset, build lasting relationships and forge collaborations across the globe. 
        </p>
       
        <h1 className="text-centa" style={{ color: '#004080',  fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Vision</h1>
        
        <p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
          The VISION of Beulah Heights is ARC, which is our stated goal of becoming: 
          </p>
         
<ul style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
<li>Academic Excellence</li>
<li>Resource Center</li>
<li>Change Agent</li>
</ul>
<h1 className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Mission</h1>
<br></br>

<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
  The mission of Beulah Heights University is to develop relevant Christian leaders for the ministry and marketplace.
</p>
<h1 className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>
Core Values</h1>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
Beulah Heights University has established the following definite core values with which we endeavor to establish as a basis 
for our daily lives. These values do not depend upon circumstances, or the events in and around our lives. These are values 
that we can depend upon to guide us in dealing with each other and even ourselves. </p>

<li className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Biblical Inerrancy</li>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
We seek to bring every aspect of personal and corporate life under the functional authority of the 
inerrant Word of God by obeying its commands, applying its principles, and refraining from dogmatism 
where the Bible is silent. We are prepared to stand on Biblical inerrancy against the opposing tide 
of compromising norms and peer pressure. </p>

<li className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Integrity</li>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
We seek to live, teach and promote a life of Godly choices in the face of temptation, and consistent 
growth toward Christ-like attitudes and behavior with the context of responsible church membership 
and involvement. We affirm the necessity of yielding to the Lordship of Jesus Christ as the unconditional 
Lord of Life, with trust and obedience as essential prerequisites for the enablement of the Holy Spirit 
to live a Christ-like life. </p>

<li className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Global Missions</li>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
We are committed to implementing Christ's mandate to fulfill the great commission by stimulating and 
training Christians for evangelistic church planting, and support ministries through global evangelization. </p>

<li className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Dedicated Servanthood</li>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
We are dedicated to train Christian leaders who practice a pattern of life which reflects the conviction 
that both individual and corporate prayer is essential in pursuit of God's purpose for holy living and 
fruitful ministry. We seek to cultivate an understanding of and commitment to worship and giving as a 
vital and appropriate response of the believer to God, as evidenced by regular and consistent practice 
in private and corporate life. We seek to be a community marked by joyful reliance upon God for material 
provision, victory over sin, growth in Christ-likeness and fruitful servanthood. </p>

<li className="text-centa" style={{ color: '#004080', fontSize: '24px', fontWeight: 'bold', maxWidth:'100%', margin: '0' }}>Diversity</li>
<br></br>
<p style={{fontFamily:'Poppins, sans-serif', fontSize:'16px'}}>
We are committed to embracing and empowering multi-ethnic and multi-cultural Christian communities 
without regard to socio-economic status for purposes of fellowship, encouragement, edification and ministry. </p>

    </div>
    <br/>
       </div>
       <footer className="footer">
          <div className="footer-content">
            <div className="contact">
              <h4><strong>Contact us</strong></h4>
              <ul>
                <li><a href="/">(+254) 743 777 277</a></li>
                <li><a href="mailto:info@beulah.ac.ke">info@beulah.ac.ke</a></li>
                <li><a href="www.beulah.ac.ke">www.beulah.ac.ke</a></li>
              </ul>
            </div>
            <div className="address">
              <h4><strong>Beulah Heights Global Initiative</strong></h4>
              <h4>Daystar Valley Road Campus</h4>
              <ul>
                <li>P.O. BOX 40858 - 00100</li>
                <li>NAIROBI, KENYA</li>
                <li>(+254) 743 777 277</li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>What to Know</strong></h4>
              <ul>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/">Academics</Link>
                </li>
                <li><a href="https://beulah.edu/admissions/">Admissions</a></li>
                <li>
                  <Link to="/Media">Media</Link>
                </li>
                <li>
                  <Link to="https://beulah.edu/academics/library/">Research</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Get Your Degree</strong></h4>
              <ul>
                <li><a href="https://beulah.edu/academics/undergraduate/">Undergraduate</a></li>
                <li><a href="https://beulah.edu/academics/master-programs/">Graduate</a></li>
                <li><a href="https://beulah.edu/academics/doctoral-programs/">Post Graduate</a></li>
                <li><a href="/Pdc">Professional Development</a></li>
              </ul>
            </div>
            <div className="links">
              <h4><strong>Who We Are</strong></h4>
              <ul>
                <li><a href="/OurStory">Our Story</a></li>
                <li><a href="/Partnerships">Partner With Us</a></li>
                <li><a href="/About">Our Team</a></li>
              </ul>
            </div>
            <div className="subscribe">
              <h4><strong>Sign up for our monthly news and updates.</strong></h4>
               <input type="text" placeholder="Email Address"style={{width: '200px'}} />
            </div>
        
          </div>
          
          <div className="footer-bottom">
            <div className="social-media">
              <ul>
                <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faYoutube} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li><a href="/"><FontAwesomeIcon icon={faInstagram} /></a></li>
              </ul>
            </div>
            <div className="copyright">
              <p><strong>&copy; 2024 Beulah Heights University, All Rights Reserved. Privacy Policy | Site Map</strong></p>
            </div>
            <div className="logo-bottom">
              <img src={FooterLogo} alt="Beulah Heights University Africa" />
            </div>
          </div>
        </footer>
     
    </div>
  );
}

export default About;